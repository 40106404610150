$system-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$body-font: 'Alte Haas Grotesk', $system-font;
$heading-font: Yoxxal, $system-font;

$background-primary: #001e26;
$background-secondary: #161616;

$color-main: #00dc82;
$color-secondary: #004e3e;

$color-white: #ccc;
