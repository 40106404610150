@import '/src/styles/base/variables';

.nav {
  background-color: $background-secondary;
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: center;
  padding: 15px;
  margin: 0;
  list-style: none;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  gap: 30px;
  align-items: baseline;
  z-index: 1;

  @media (min-width: 720px) {
    flex-flow: column nowrap;
    align-items: center;
    gap: 15px;
    right: auto;
    bottom: 0;
  }

  li {
    a {
      transition: 0.2s all ease-in-out;
      color: $color-main;

      &.active {
        color: $color-white;
      }

      &:hover {
        color: $color-white;
      }
    }
  }
}
