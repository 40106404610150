@import './base/variables';
@import './base/fonts';
@import './base/reset';

body,
button {
  color: $color-white;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-main;
}

h1 {
  font-size: calc(40px + 2vmin);
}

a {
  color: $color-main;
}
