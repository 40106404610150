footer {
  display: none;
  margin-left: auto;
  margin-top: auto;
  padding: 15px;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 11px;

  @media (min-width: 720px) {
    display: block;
  }
}
