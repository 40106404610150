@import '/src/styles/base/variables';

.title {
  font-size: calc(45px + 2vmin);
  line-height: calc(45px + 2vmin);
  font-weight: bold;
  text-align: left;
  color: $color-white;

  span {
    display: block;
  }
}

.button-wrapper {
  margin-top: 30px;
}
